<template>
    <div class="con-wrap">
        <CarrotTitle title="재물 조사 관리">
            - 재물조사를 등록하고 현황 및 이력을 확인합니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div class="mt-40 mb-20">
                  <router-link to="AssetManagementReportAdd"><button class="btn-default float-right">등록</button></router-link>
                    <div class="clear"></div>
                </div>
                <table class="table-col">
                    <colgroup>
                      <col width="50">
                      <col width="300">
                      <col width="*">
                      <col width="120">
                      <col width="120">
                      <col width="100">
                      <col width="100">
                      <col width="150">
                      <col width="120">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>조사 기간</th>
                        <th>제목</th>
                        <th>상태</th>
                        <th>대상인원</th>
                        <th>신고완료인원</th>
                        <th>대상장비수</th>
                        <th>신고 완료장비수</th>
                        <th>등록일<br>등록자</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(irow, i) in assets.list" :key="i" class="pointer"  @click="assets.showDetail(irow.idx)">
                        <td>{{ irow.num }}</td>
                        <td>{{ irow.sdate }} ~ {{ irow.edate }}</td>
                        <td class="txt-ellipsis" :title="irow.title"><span class="btn-view">{{ irow.title }}</span></td>
                        <td>{{irow.report_status.status}}</td>
                        <td>{{irow.report_status.member_total_cnt}}</td>
                        <td>{{irow.report_status.member_report_cnt}}</td>
                        <td>{{irow.report_status.asset_total_cnt}}</td>
                        <td>{{irow.report_status.asset_report_cnt}}</td>
                        <td>{{ irow.wdate }}<br>{{ irow.create_idx>0?irow.ename+'('+irow.kname+')':'-' }}</td>
                      </tr>
                      <tr v-if="assets.total==0">
                        <td colspan="9">검색 결과가 없습니다.</td>
                      </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="assets.total" :list_per_page="assets.rows" v-model="assets.page" @change="assets.doSearch"></CarrotPaging>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import { useStore } from 'vuex';

export default {
    layout:"myPIMS",

    components: {
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const assets = reactive({
            categories : [],

            page   : 1,
            rows   : 20,

            cat1   : "",
            cat2   : "",

            sdate  : "",
            edate  : "",
            payment_sdate  : "",
            payment_edate  : "",
            idx_office : "0",
            idx_hq     : "",

            serialno : "",
            codename : "",
            model    : "",

            usage        : "",
            state        : "",

            selected : [],

            list : [], total : 0,

           

            showDetail: (idx) => {
                router.push({
                    name   : 'myPIMS-AssetManagementReportEdit-idx',
                    params : { idx:idx, page:assets.page }
                });
            },

            setCat1 : () =>{
                assets.cat2 = "";
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        assets.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    page   : assets.page,
                    rows   : assets.rows,

                    sdate  : assets.sdate,
                    edate  : assets.edate,

                    payment_sdate  : assets.payment_sdate,
                    payment_edate  : assets.payment_edate,

                    idx_office : assets.idx_office,
                    idx_hq       : assets.idx_hq,

                    serialno : assets.serialno,
                    codename : assets.codename,
                    model    : assets.model,

                    usage : assets.usage,
                    state : assets.state
                };

                if( typeof assets.cat1 == 'undefined' || assets.cat1 == '' ){
                    params.cat1 = '';
                    params.cat2 = '';
                } else {
                    params.cat1 = assets.cat1.name;
                    if( typeof assets.cat2 == 'undefined' || assets.cat2 == '' ){
                        params.cat2 = '';
                    } else {
                        params.cat2 = assets.cat2;
                    }
                }

                axios.get('/rest/mypims/getAssetsPopupList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        assets.list  = res.data.list;
                        assets.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

        });

        onMounted(() => {
            // Mounted
            let auth = false;
            if (store.state.isSalesAdmin || store.state.isManageSupportAdmin) //자산관리는 총무+회계
              auth = true;

            if( auth==false ) {
                router.go(-1);
                Swal.fire({
                    title : '자산관리 등록',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            assets.getCategory();
            assets.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {assets};
    }
}
</script>

<style lang="scss" scoped>
</style>